import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import SEO from 'sections/Layout/SEO';

const ScreverPromo = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const seo = {
    title: 'Screver Promo video | Screver Feedback Management Solution',
  };

  return (
    <>
      <SEO seo={seo} />

      <ReactPlayer
        width={'100%'}
        height={'100vh'}
        // className={styles.video}
        playing={isPlaying}
        playsinline={true}
        controls={true}
        onClick={() => setIsPlaying(!isPlaying)}
        light={
          'https://wp.screver.com/wp-content/uploads/2022/08/promo-video-poster.jpg'
        }
        url={
          'https://wp.screver.com/wp-content/uploads/2022/08/screver-webm.webm'
        }
      />
    </>
  );
};

export default ScreverPromo;
